<template>
  <section>
    <section class="navBack">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" cols="12" class="topNav">
            <ul class="navList">
              <li><router-link to="/">Home</router-link></li>
              <li v-if="!loading && product.category">
                <v-icon> mdi-chevron-right </v-icon>
                <router-link :to="`/search?category=${product.category}`">{{
                  product.category
                }}</router-link>
              </li>
              <li v-if="!loading">
                <v-icon> mdi-chevron-right </v-icon> {{ product.productName }}
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="my-5 mb-15">
      <v-container class="container-custom">
        <v-row>
          <v-col md="8" cols="12">
            <div class="productImages">
              <LoadingSkeleton :image="4" v-if="loading" />
              <SingleSilder
                :images="product.medias"
                v-if="!loading && product"
              />
              <div class="danger">
                <v-chip v-if="product.discountPercentage != 0" small
                  >Save Rs.
                  {{
                    numberFormat(
                      product.discountPercentage * 100
                    )
                  }}</v-chip
                >
              </div>
            </div>
            <div class="detailBox">
              <h2>Overview</h2>
              <div v-if="loading">
                <LoadingSkeleton :text="6" class="mt-3" />
              </div>
              <div v-if="!loading">
                <div class="mt-3" v-if="!schedule">
                  <div v-html="product.description"></div>
                </div>

                <ul class="detailBoxList">
                  <li v-if="product.motor != undefined">
                    <span class="title"> Product Type </span>
                    <strong> {{ product.motor }} </strong>
                  </li>
                  <li v-if="product.power != undefined && product.power != '-'">
                    <span class="title"> Max. Power </span>
                    <strong> {{ product.power }} </strong>
                  </li>
                  <li v-if="product.year">
                    <span class="title"> Make Year </span>
                    <strong> {{ product.year }} </strong>
                  </li>
                  <li v-if="product.bodyType && product.bodyType != '-'">
                    <span class="title"> Body Type </span>
                    <strong> {{ product.bodyType }} </strong>
                  </li>

                  <li
                    v-if="
                      product.fuelType != undefined && product.fuelType != '-'
                    "
                  >
                    <span class="title"> Fuel </span>
                    <strong> {{ product.fuelType }} </strong>
                  </li>
                  <li v-if="product.range != undefined && product.range != ''">
                    <span class="title"> Range </span>
                    <strong> {{ product.range }} </strong>
                  </li>

                  <li v-if="product.groundClearance != '-'">
                    <span class="title"> Ground Clearance </span>
                    <strong> {{ product.groundClearance }} </strong>
                  </li>
                  <li v-if="product.chargingVia != undefined">
                    <span class="title">Charging Station </span>
                    <strong> {{ product.chargingVia }} </strong>
                  </li>
                  <li v-if="product.torque != '-'">
                    <span class="title"> Torque </span>
                    <strong> {{ product.torque }} </strong>
                  </li>
                  <li v-if="product.transmissionType != undefined">
                    <span class="title"> Transmission Type</span>
                    <strong> {{ product.transmissionType }} </strong>
                  </li>
                  <li v-if="product.serviceCost != '-'">
                    <span class="title"> Service Cost </span>
                    <strong> Rs. {{ product.serviceCost }} </strong>
                  </li>
                  <li v-if="product.acceleration != '-'">
                    <span class="title"> Acceleration </span>
                    <strong> {{ product.acceleration }}</strong>
                  </li>
                  <li v-if="product.batteryCapacity != '-'">
                    <span class="title"> Battery Capacity </span>
                    <strong> {{ product.batteryCapacity }} </strong>
                  </li>
                  <li v-if="product.batteryType != '-'">
                    <span class="title"> Battery Type </span>
                    <strong> {{ product.batteryType }} </strong>
                  </li>
                  <li v-if="product.seatingCapacity != '-'">
                    <span class="title"> Seating Capacity </span>
                    <strong> {{ product.seatingCapacity }}</strong>
                  </li>
                  <li v-if="product.chargingTime != '-'">
                    <span class="title"> Charging Time </span>
                    <strong> {{ product.chargingTime }}</strong>
                  </li>
                  <li v-if="product.bootSpace != '-'">
                    <span class="title"> Boot Space </span>
                    <strong> {{ product.bootSpace }} <span>ltr</span></strong>
                  </li>
                  <li v-if="product.chargingVia != '-'">
                    <span class="title"> Chargning Station </span>
                    <strong> {{ product.chargingVia }}</strong>
                  </li>
                  <li
                    v-for="(data, index) in product.attributes"
                    :key="index + 'attribute'"
                  >
                    <span class="title">{{ data.attributeKey }}</span>
                    <strong>{{ data.attributeValue }}</strong>
                  </li>
                  <li>
                    <span class="title">Available Color</span>
                    <strong
                      ><span
                        v-for="(data, index) in product.color"
                        :key="index + 'color'"
                        ><div
                          :style="{ background: data }"
                          class="colorBox"
                          v-if="data != ''"
                        >
                          {{ data }}
                        </div>
                      </span></strong
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="detailBox"
              v-for="(data, index) in product.features"
              :key="index + 'keys'"
            >
              <h2>{{ data.title }}</h2>
              <div class="mt-3">
                <ul class="detailBoxList detailSpecifications">
                  <li
                    v-for="(data, index) in data.items"
                    :key="index + 'feature'"
                  >
                    <span class="title titleNormal"
                      ><v-icon> mdi-circle </v-icon> {{ data.item }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="detailBox">
              <h2>Community</h2>
              <CommunityBox :connectedTo="product._id" v-if="!loading" />
            </div>
            <div class="detailBox">
              <h2 class="mb-3">Reviews</h2>
              <ReviewCard :id="product._id" v-if="!loading" />
            </div>
          </v-col>
          <v-col md="4">
            <div class="filterationBox" v-if="!loading && product">
              <header class="title">
                {{ product.productName }}
                <v-icon
                  class="heart"
                  v-if="!loadingWish && !product.wishlist"
                  @click="addToWishlist"
                >
                  mdi-heart-outline
                </v-icon>
                <v-icon
                  class="heart filled colorRen"
                  v-if="!loadingWish && product.wishlist"
                  @click="addToWishlist"
                >
                  mdi-heart
                </v-icon>
                <v-progress-circular
                  indeterminate
                  v-if="loadingWish"
                  size="25"
                  width="3"
                ></v-progress-circular>
              </header>
              <p>Brand: {{ product.brand }}</p>
              <div class="ratingProduct">
                <v-rating
                  readonly
                  :value="product.rating"
                  size="25"
                  color="orange"
                  background-color="orange"
                ></v-rating>
                <small class="ml-1">({{ product.rating }})</small>
              </div>

              <strong class="priceBox" v-if="product.discountPercentage != 0">
                <strike>Rs. {{ numberFormat(product.price) }}</strike> Rs.
                {{
                  numberFormat(product.price - product.discountPercentage * 100)
                }}
              </strong>
              <strong class="priceBox" v-if="product.discountPercentage == 0">
                Rs. {{ numberFormat(product.price) }}
              </strong>
              <div v-if="!schedule">
                <p>Category: {{ product.category }}</p>

                <v-chip
                  color="red"
                  small
                  :class="{ qtyClass: product.quantity > 0 }"
                  class="mb-5"
                  >{{ product.quantity > 0 ? "In Stock" : "Out Of Stock" }}
                </v-chip>
                <v-text-field outlined v-model="quantity" filled></v-text-field>
              </div>

              <div>
                <v-btn
                  class="primaryBtn mb-3"
                  width="100%"
                  height="50px"
                  v-if="!checkoutLoading"
                  @click="addToCheckout"
                  >Buy Now <v-icon> mdi-bag-personal-outline </v-icon></v-btn
                >
                <v-btn
                  width="100%"
                  class="primaryBtn mb-3"
                  height="50px"
                  @click="bookForm"
                >
                  Book A Test Drive
                </v-btn>
                <v-btn
                  v-if="checkoutLoading"
                  width="100%"
                  class="primaryBtn mb-3"
                  height="50px"
                  loading
                ></v-btn>
                <v-btn
                  width="100%"
                  class="mb-3"
                  height="50px"
                  v-if="!cartLoading"
                  @click="addToCart"
                  >Add To Cart <v-icon> mdi-cart-outline </v-icon>
                </v-btn>
                <v-btn
                  v-if="cartLoading"
                  width="100%"
                  class="mb-3"
                  height="50px"
                  loading
                ></v-btn>
                <CompareAction :data="product" />
              </div>
              <div v-if="schedule">
                <v-btn class="primaryBtn mb-3" width="100%" height="50px"
                  >Contact Us <v-icon> mdi-phone </v-icon></v-btn
                >
                <v-btn width="100%" class="mb-3" height="50px"
                  >Schedule Test Drive <v-icon> mdi-steering </v-icon>
                </v-btn>

                <header class="title mt-3 mb-5">EMI Calculator</header>
                <EMICalculator />
              </div>
            </div>
            <div class="filterationBox" v-if="loading">
              <LoadingSkeleton :title="1" :text="6" :button="2" class="mt-3" />
            </div>
            <div class="filterationBox" v-if="schedule">
              <header>Want help with installation?</header>
              <p>
                Our dedicated team will gather quotes from certified technicians
                in your area and help you find the right fit at the best rate.
                Learn More
              </p>
              <ul class="deliveryBox">
                <li>2-3 Days Normal Delivery</li>
                <li>24Hours Express Delivery</li>
              </ul>
            </div>
            <div class="filterationBox" v-if="schedule">
              <header>Interested in Finance?</header>
              <p>
                Avail upto 80% of the car value in finance at the lowest
                possible interest rates
              </p>
              <v-btn width="100%" class="mb-3" height="50px"
                >Yes, I’m interested.</v-btn
              >
            </div>
            <div class="filterationBox" v-if="schedule">
              <header>
                isAvailable Insurance
                <v-icon @click="toggleBoxAct"> mdi-chevron-down </v-icon>
              </header>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="schedule">
          <v-col md="12">
            <h2 class="mb-5">Blogs Related to Product</h2>
            <BlogSlider :images="images" />
          </v-col>
        </v-row>
        <v-row v-if="schedule">
          <v-col md="12">
            <h2 class="mb-5">Content Related To Product</h2>
            <ContentSlider :images="images" />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <h2 class="mb-5">Similar Product</h2>
            <v-row v-if="loadingSimilar">
              <v-col v-for="n in 4" :key="n" md="3">
                <LoadingSkeleton :image="2" :title="2" :text="6" />
              </v-col>
            </v-row>
            <ProductCardSlider :products="products" v-if="!loadingSimilar" />
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar v-model="snakbar"> {{ message }}</v-snackbar>
    </section>
    <div class="bookingForm" v-if="!loading && bookStatus">
      <Book :connectedTo="product._id" @closeBook="bookStatusAct" />
    </div>
  </section>
</template>

<script>
import SingleSilder from "@/components/SingleSilder";
import Book from "@/components/Book";
import CommunityBox from "@/components/CommunityBox";
import ReviewCard from "@/components/ReviewCard";
import EMICalculator from "@/components/EMICalculator";
import BlogSlider from "@/components/BlogSlider";
import ContentSlider from "@/components/ContentSlider";
import ProductCardSlider from "@/components/ProductCardSlider";
import LoadingSkeleton from "@/components/LoadingSkeleton";
import { mapActions, mapGetters } from "vuex";
import CompareAction from "@/components/CompareAction";
export default {
  name: "Home",
  data: () => ({
    slickOptions: {
      slidesToShow: 3,
      dots: false,
      arrows: false,
      fade: false,
    },
    bookStatus: false,
    toggleBox: false,
    schedule: false,
    images: [],
    product: undefined,
    loading: true,
    quantity: 1,
    cartLoading: false,
    products: undefined,
    limit: 10,
    page: 1,
    loadingSimilar: true,
    productType: "product",
    snakbar: false,
    message: undefined,
    loadingWish: false,
    checkoutLoading: false,
  }),
  methods: {
    ...mapActions([
      "getSingleFrotendProduct",
      "postCart",
      "getFrotendProducts",
      "postWishlist",
    ]),
    numberFormat(givenNumber) {
      let internationalNumberFormat = new Intl.NumberFormat("en-IN");
      return internationalNumberFormat.format(givenNumber);
    },
    bookForm() {
      this.bookStatus = true;
    },
    toggleBoxAct() {
      this.toggleBox = !this.toggleBox;
    },
    bookStatusAct() {
      this.bookStatus = false;
    },
    async addToWishlist() {
      if (this.allLogin == undefined) {
        this.$router.push("/login");
        sessionStorage.setItem("wishlist", this.product._id);
      } else {
        this.loadingWish = true;
        await this.postWishlist({ productId: this.product._id });
        this.loadingWish = false;
        this.product.wishlist = !this.product.wishlist;
      }
    },
    async addToCheckout() {
      this.checkoutLoading = true;
      let data = {};
      data.productId = this.product._id;
      data.addedToCheckout = true;
      data.quantity = this.quantity;
      data.actionType = "increment";
      await this.postCart(data);
      this.checkoutLoading = false;
      sessionStorage.setItem("product", this.$route.params.id);
      this.snakbar = true;
      this.message = this.allCarts.message;
      this.$router.push("/checkout");
    },
    async addToCart() {
      this.cartLoading = true;
      let data = {};
      data.productId = this.product._id;
      data.quantity = this.quantity;
      data.actionType = "increment";
      await this.postCart(data);
      this.cartLoading = false;
      sessionStorage.setItem("product", this.$route.params.id);
      this.snakbar = true;
      this.message = this.allCarts.message;
    },
  },
  components: {
    Book,
    SingleSilder,
    CommunityBox,
    ReviewCard,
    BlogSlider,
    ProductCardSlider,
    ContentSlider,
    EMICalculator,
    LoadingSkeleton,
    CompareAction,
  },
  computed: {
    ...mapGetters(["allProduct", "allProducts", "allCarts", "allLogin"]),
  },
  async created() {
    await this.getSingleFrotendProduct(this.$route.params.id);

    this.product = this.allProduct.results;
    if (this.product.productType == "product") {
      this.schedule = false;
    }
    this.loading = false;
    await this.getFrotendProducts({
      limit: this.limit,
      page: this.page,
      productType: this.product.productType,
    });
    this.products = this.allProducts.results;
    this.loadingSimilar = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.width100 {
  width: 100%;
}
.v-btn {
  min-height: 5 0px;
  width: 200px;
  color: #0878d4 !important;
  background: #fff !important;
  border: 1px solid #0878d4;
  box-shadow: none !important;
}
.v-chip {
  color: #fff !important;
}
.qtyClass {
  background: green !important;
}
.filterationBox {
  border: 1px solid #efefef;
  padding: 20px 20px 10px;
  margin-bottom: 20px;
  header {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    font-weight: 500;
  }
  .toogleBox {
    display: none;
  }
  .filterBox {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 10px;
    li {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: -15px;
      div {
        margin-top: -15px;
      }
    }
  }
}
.v-input {
  margin-top: 0 !important;
}
.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
strike {
  font-size: 14px;
  color: #8c8c8c;
}
.detailBox {
  border: 2px solid #f4f4f4;
  padding: 20px;
  margin-top: 20px;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .detailBoxList {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 33.33%;
      display: flex;
      padding: 15px 0;
      flex-flow: column;
      .title {
        font-size: 14px !important;
        font-weight: 400;
        color: #8c8c8c;
        .v-icon {
          font-size: 18px;
          color: #0878d4;
        }
      }
      strong {
        font-size: 16px;
      }
    }
  }
  .detailKeys {
    .titleNormal {
      font-size: 16px !important;
    }
    li {
      width: 50%;
    }
  }
  .detailSpecifications {
    .titleNormal {
      font-size: 16px !important;
    }
    li {
      width: 100%;
      padding: 5px 0;
      .title {
        font-size: 16px !important;
        display: flex;
        align-items: center;
        .v-icon {
          margin-right: 10px;
        }
      }
    }
  }
}

h2 {
  font-size: 30px;
}
.priceBox {
  font-size: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: block;
}
header {
  .title {
    font-size: 20px;
    font-weight: 700;
  }
}
.primaryBtn {
  background: #0878d4 !important;
  color: #fff !important;
  .v-icon {
    font-size: 18px;
    margin-left: 10px;
  }
}
.v-btn {
  .v-icon {
    font-size: 18px;
    margin-left: 10px;
  }
}
.deliveryBox {
  padding-left: 0;
  list-style: none;
  li {
    background: #f4f4f4;
    padding: 15px;
    margin-bottom: 15px;
  }
}
.colorRen {
  color: red;
}
.ratingProduct {
  display: flex;
  align-items: center;
}
.bookingForm {
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  padding: 30px;
}
strong {
  text-transform: capitalize;
  span {
    text-transform: lowercase;
  }
}
.colorBox {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 5px;
  margin-right: 5px;
  margin-block: 4px;
  border: 1px solid #918888;
  // height: 10px;
  // width: 10px;
  text-transform: uppercase;
}
.productImages {
  position: relative;
}
</style>