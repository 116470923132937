<template>
  <section>
    <div class="bookingForm">
      <v-form>
        <v-row>
          <v-col md="12">
            <div class="d-flex justify-space-between">
              <h3>Book A Test Drive</h3>
              <v-icon @click="closeBookPopup"> mdi-close </v-icon>
            </div>
          </v-col>
          <v-col md="12" class="pb-0">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="bookingDate"
                  label="Booking Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker v-model="bookingDate"></v-date-picker>
            </v-menu>
          </v-col>

          <v-col md="12" class="pt-0">
            <v-menu
              ref="menu"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="bookingTime"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="bookingTime"
                  placeholder="Booking Time"
                  prepend-icon=""
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu2"
                v-model="bookingTime"
                full-width
                @click:minute="$refs.menu.save(bookingTime)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col md="12" class="pt-0">
            <v-text-field
              v-model="phoneNumber"
              placeholder="Phone Number"
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="12" class="pt-0">
            <v-text-field
              v-model="fullName"
              placeholder="Fullname"
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="12" class="pt-0">
            <v-text-field
              v-model="address"
              placeholder="Address"
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="12" class="pt-0">
            <v-text-field
              v-model="nearestLandMark"
              placeholder="Nearest landmark"
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="12" class="pt-0">
            <vue-dropzone
              class="mb-2"
              ref="myVueDropzone"
              id="dropzone"
              :options="dropzoneOptions"
            ></vue-dropzone>
            <div class="mb-5">
              <small>Upload your Citizenship, License or Passport</small>
            </div>
          </v-col>

          <v-col md="12">
            <v-btn class="primary" v-if="!loading" large @click="bookingCreate"
              >Book Now</v-btn
            >
            <v-btn class="primary" v-if="loading" large loading></v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-snackbar v-model="snackbar" color="green"> {{ message }}</v-snackbar>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "Book",
  props: ["connectedTo"],
  data: () => ({
    menu: false,
    menu2: false,
    snackbar: false,
    bookingDate: undefined,
    fullName: undefined,
    address: undefined,
    nearestLandMark: undefined,
    phoneNumber: undefined,
    bookingTime: undefined,
    loading: false,
    message: undefined,
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      thumbnailWidth: 150,
      maxFilesize: 10,
      headers: { "My-Awesome-Header": "header value" },
      addRemoveLinks: true,
    },
    images: [],
  }),
  components: {
    vueDropzone: vue2Dropzone,
  },
  methods: {
    ...mapActions(["postBooking"]),
    closeBookPopup() {
      this.$emit("closeBook", false);
    },
    async bookingCreate() {
      this.loading = true;
      this.images = [];
      let fileBundles = this.$refs.myVueDropzone.getAcceptedFiles();
      fileBundles.forEach((ele) => {
        if (ele.type.split("/")[0] == "image") {
          this.images.push(ele);
        }
      });
      let data = new FormData();
      data.append("connectedTo", this.connectedTo);
      if (this.bookingDate != undefined) {
        data.append("bookingDate", this.bookingDate);
      }
      if (this.fullName != undefined) {
        data.append("fullName", this.fullName);
      }
      if (this.address != undefined) {
        data.append("address", this.address);
      }
      if (this.nearestLandMark != undefined) {
        data.append("nearestLandMark", this.nearestLandMark);
      }
      if (this.phoneNumber != undefined) {
        data.append("phoneNumber", this.phoneNumber);
      }
      if (this.bookingTime != undefined) {
        data.append("bookingTime", this.bookingTime);
      }

      if (this.images != undefined) {
        this.images.forEach((ele) => {
          data.append("images", ele);
        });
      }

      await this.postBooking(data);
      this.message = "Your vehicle has been book for test drive";
      this.snackbar = true;
      this.loading = false;
      setTimeout(() => {
        this.closeBookPopup();
      }, 1000);
    },
  },
  computed: {
    ...mapGetters(["allBooking"]),
  },
};
</script>

<style lang="scss" scoped>
.bookingForm {
  background: #fff;
  padding: 30px;
  width: 30%;
  margin: 50px auto 0;
  border-radius: 5px;
}
</style>