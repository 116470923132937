<template>
  <section class="blogSlider">
    <ul class="cursorIcons">
      <li><v-icon @click="prev"> mdi-chevron-left </v-icon></li>
      <li><v-icon @click="next"> mdi-chevron-right </v-icon></li>
    </ul>
    <div class="slidingSection">
      <slick ref="slick" :options="slickOptions">
        <div class="px-1" v-for="(img, index) in images" :key="'as' + index">
          <BlogBox :hr="false"  />
        </div>
      </slick>
    </div>
  </section>
</template>

<script>
import Slick from "vue-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import BlogBox from "@/components/BlogBox";
export default {
  name: "BlogSlider",
  components: { Slick, BlogBox },
  props: ["images"],
  data: () => ({
    slickOptions: {
      slidesToShow: 4,
      dots: false,
      arrows: false,
      fade: false,
    },
    media: [],
    index: 0,
    visible: false,
  }),
  methods: {
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },
  },
};
</script>

<style lang="scss" scoped>
.blogSlider {
  position: relative;
}

.cursorIcons {
  display: flex;
  list-style: none;
  padding-left: 0;
  position: absolute;
  top: -65px;
  right: 3px;
  li {
    margin-left: 10px;
    .v-icon {
      padding: 10px;
      background: #f4f4f4;
    }
  }
}
</style>
