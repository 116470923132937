<template>
  <section>
    <div class="reviewedBox">
      <v-rating
        :value="item.rating"
        class="mr-2 mb-2"
        size="20"
        color="orange"
        readonly
        background-color="orange"
      ></v-rating>
      <h3>{{ item.title }}</h3>
      <p>
        {{ item.description }}
      </p>
      <footer>
        <ul>
          <li>
            <v-icon> mdi-account-outline </v-icon>
            By: {{ item.fullname }}
          </li>
          <li>
            <v-icon> mdi-calendar </v-icon>
            {{ item.createdAt | moment("MMMM Do YYYY : hh :mm A") }}
          </li>
        </ul>
        <ul>
          <!-- <li><v-icon> mdi-thumb-up-outline </v-icon> Like ({{item.likes}})</li>
          <li><v-icon> mdi-comment-outline </v-icon> Reply ({{item.reply}})</li> -->
          <li></li>
          <li></li>
        </ul>
      </footer>
    </div>
  </section>
</template>

<script>
export default {
  name: "ReviewBox",
  props: ["item"],
};
</script>

<style lang="scss" scoped>
.reviewedBox {
  border: 2px solid #f4f4f4;
  padding: 15px;
  margin-bottom: 20px;
  h3 {
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
  }
  footer {
    display: flex;
    justify-content: space-between;
    ul {
      padding-left: 0;
      list-style: none;
      display: flex;
      font-size: 14px;
      .v-icon {
        font-size: 18px;
        margin-right: 5px;
      }
      li {
        color: #8c8c8c;
        margin-right: 15px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
      }
      &:last-of-type {
        margin-right: 0;
        margin-left: 15px;
      }
    }
  }
}
</style>