<template>
  <section>
    <v-btn class="primaryBtn" @click="compareAct">Compare</v-btn>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "CompareAction",
  props:['data'],
  methods: {
    ...mapActions(['setCompareIds']),
    compareAct() {
      this.setCompareIds(this.data);
      this.$router.push("/compare")
    },
  },
};
</script>

<style lang="scss" scoped>
.primaryBtn{
  width: 100%!important;
}
</style>