<template>
  <section>
    <v-form>
      <v-row>
        <v-col md="12">
          <v-row>
            <div class="widthFull">
              <v-label>Car Amount</v-label>
              <div class="flexBox">
                <ul>
                  <li class="no-border-right">Rs</li>
                </ul>
                <v-text-field
                  class="mt-1"
                  @keyup="loadCalculator"
                  placeholder="Car Loan Amount"
                  outlined
                  v-model="amount"
                  filled
                ></v-text-field>
              </div>
            </div>
            <div class="widthFull">
              <v-label>Downpayment</v-label>
              <div class="flexBox">
                <ul>
                  <li class="no-border-right">Rs</li>
                </ul>
                <v-text-field
                  class="mt-1"
                  @keyup="loadCalculator"
                  placeholder="Downpayment"
                  outlined
                  v-model="downpayment"
                  filled
                ></v-text-field>
              </div>
            </div>
            <div class="widthFull">
              <v-label>Interest Rate</v-label>
              <div class="flexBox">
                <v-text-field
                  class="mt-1"
                  @keyup="loadCalculator"
                  placeholder="Interest Rate"
                  outlined
                  filled
                  v-model="interestRate"
                ></v-text-field>
                <ul>
                  <li class="no-border-left">%</li>
                </ul>
              </div>
            </div>
            <div class="widthFull">
              <v-label>Loan Tenure</v-label>
              <div class="flexBox">
                <v-text-field
                  class="mt-1"
                  @keyup="loadCalculator"
                  placeholder="Loan Tenure"
                  outlined
                  v-model="loanTenure"
                  filled
                ></v-text-field>
                <ul>
                  <li
                    class="no-border-left"
                    @click="selectDuration('year')"
                    :class="{ active: loanPer == 'year' }"
                  >
                    Yr
                  </li>
                  <li
                    class="no-border-left"
                    @click="selectDuration('month')"
                    :class="{ active: loanPer == 'month' }"
                  >
                    Mo
                  </li>
                </ul>
              </div>
            </div>
            <div class="flex widthFull mb-4">
              <ul class="totalSection">
                <li><span>Loan EMI : </span>Rs. {{ numberFormat(emi.emi) }}</li>
                <li>
                  <span>Total Interest Payable : </span>Rs.
                  {{ numberFormat(emi.total - (amount - downpayment)) }}
                </li>
                <li>
                  <span>Total : </span>Rs.
                  {{ numberFormat(emi.total) }}
                </li>
              </ul>
            </div>
          </v-row>
        </v-col>
        <v-col md="5" v-if="false">
          <v-row>
            <v-col md="12">
              <apexchart
                width="500"
                type="pie"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </section>
</template>

<script>
export default {
  name: "EMICalculator",
  data: () => ({
    amount: 100000,
    downpayment: 0,
    interestRate: 10,
    loanTenure: 12,
    loanAmount: 0,
    totalAmount: 0,
    loanPer: "month",
    emi: {},
    series: [10, 20],
    chartOptions: {
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(2) + "%";
        },
      },
      legend: {
        show: false,
      },
      chart: {
        width: 1080,
        type: "pie",
      },
      labels: ["Total Payment", "Total Interest Payable"],
    },
  }),
  methods: {
    numberFormat(givenNumber) {
      let internationalNumberFormat = new Intl.NumberFormat("en-US");
      return internationalNumberFormat.format(givenNumber);
    },
    selectDuration(data) {
      this.loanPer = data;
      if (this.loanPer == "year") {
        this.loanTenure = 1;
      } else {
        this.loanTenure = 12;
      }
      this.loadCalculator();
    },
    loadCalculator() {
      if (this.downpayment == "") {
        this.downpayment = 0;
      }
      if (this.amount == "") {
        this.amount = 100000;
      }
      if (this.interestRate == "") {
        this.interestRate = 0;
      }
      if (this.loanTenure == "") {
        if (this.loanPer == "year") {
          this.loanTenure = 1;
        } else {
          this.loanTenure = 12;
        }
      }

      this.amount = parseFloat(this.amount);
      this.downpayment = parseFloat(this.downpayment);
      this.interestRate = parseFloat(this.interestRate);
      this.loanTenure = parseFloat(this.loanTenure);
      let loanYear =
        this.loanPer == "year" ? this.loanTenure * 12 : this.loanTenure;
      const interest = this.interestRate / 1200;
      let term = loanYear;
      let top = Math.pow(1 + interest, term);
      let bottom = top - 1;
      let ratio = top / bottom;
      this.loanAmount = (this.amount - this.downpayment) * interest * ratio;
      this.totalAmount = this.loanAmount * term;
      this.emi = {
        emi: this.loanAmount.toFixed(0),
        total: this.totalAmount.toFixed(0),
      };

      this.series.pop();
      this.series.pop();
      this.series.push(parseFloat((this.amount - this.downpayment).toFixed(2)));
      this.series.push(
        parseFloat(
          (this.totalAmount - (this.amount - this.downpayment)).toFixed(2)
        )
      );
    },
  },
  created() {
    this.loadCalculator();
  },
};
</script>
<style lang="scss" scoped>
.flexBox {
  display: flex;
  width: 100%;
  ul {
    display: flex;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    margin-top: 4px;
    li {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #9b9a9a;
      font-size: 18px;
      cursor: pointer;
      &.active {
        background: rgb(226, 231, 226);
      }
    }
  }
}
.v-text-field {
  border-radius: 0;
    width: 100%;
}
.totalSection {
  list-style: none;
  padding-left: 0;
  li {
    font-size: 18px;
    font-weight: 600;
    line-height: 35px;
    display: flex;
    justify-content: flex-start;
    span {
      display: block;
      margin-right: 5px;
    }
  }
}
.no-border {
  &-left {
    border-left: 0 !important;
  }
  &-right {
    border-right: 0 !important;
  }
}
.flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-flow: column;
}
.widthFull{
    padding: 0 15px;
    width: 100%;
}
</style>