<template>
  <section>
    <Loading v-if="loading" />
    <ul class="community" v-if="!loading">
      <li
        v-for="(data, index) in communities.results"
        :key="index + 'community'"
      >
        <div class="commentFlex">
          <span>
            <img :src="data.profileImage" alt="" />
          </span>
          <div>
            <div class="nameDate">
              <strong>{{ data.fullname }}</strong>
              <span>
                <v-icon> mdi-calendar </v-icon>
                {{ data.createdAt | moment("MMMM Do YYYY") }}</span
              >
            </div>
            <p>
              {{ data.message }}
            </p>
            <ul class="detailIcon">
              <li>
                <v-icon> mdi-thumb-up-outline </v-icon> Like ({{ data.likes }})
              </li>
              <li>
                <v-icon> mdi-comment-outline </v-icon> Reply ({{
                  data.replyCommentsCount
                }})
              </li>
              <li>
                <v-icon> mdi-alert-outline </v-icon> Report ({{ data.reports }})
              </li>
            </ul>
          </div>
        </div>

        <ul class="community" v-if="data.replyCommentsCount > 0">
          <li
            v-for="(dat, index) in data.replayComments"
            :key="index + 'replycommunity'"
          >
            <div class="commentFlex">
              <span>
                <img :src="dat.profileImage" alt="" />
              </span>
              <div>
                <div class="nameDate">
                  <strong>{{ dat.fullname }}</strong>
                  <span>
                    <v-icon> mdi-calendar </v-icon>
                    {{ dat.createdAt | moment("MMMM Do YYYY") }}</span
                  >
                </div>
                <p>
                  {{ dat.message }}
                </p>
                <ul class="detailIcon">
                  <li>
                    <v-icon> mdi-thumb-up-outline </v-icon> Like ({{
                      dat.likes
                    }})
                  </li>
                  <li>
                    <v-icon> mdi-comment-outline </v-icon> Reply ({{
                      dat.replyCommentsCount
                    }})
                  </li>
                  <li>
                    <v-icon> mdi-alert-outline </v-icon> Report ({{
                      dat.reports
                    }})
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <div v-if="!loading">
      <div v-if="communities.pagination.total == 0">No community</div>
      <ul class="pagination" v-if="communities.pagination.total != 0">
        <!-- <li>
        <v-icon> mdi-chevron-left </v-icon>
      </li> -->
        <li>{{ communities.pagination.total }} Community</li>
        <!-- <li>
        <v-icon> mdi-chevron-right </v-icon>
      </li> -->
      </ul>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "./Loading.vue";
export default {
  name: "CommunityBox",
  props: ["connectedTo"],
  data: () => ({
    communities: [],
    page: 1,
    limit: 10,
    loading: true,
  }),
  components: {
    Loading,
  },
  methods: {
    ...mapActions(["getCommunities"]),
  },
  computed: {
    ...mapGetters(["allCommunities"]),
  },
  async created() {
    await this.getCommunities({
      page: this.page,
      limit: this.limit,
      connectedTo: this.connectedTo,
    });
    this.communities = this.allCommunities;
    this.loading = false;
  },
};
</script>
<style lang="scss" scoped>
.community {
  padding-left: 0;
  list-style: none;
  & > li {
    display: flex;
    flex-flow: column;
    .community {
      margin-left: 70px;
    }
    .commentFlex {
      padding: 20px 0;
      .nameDate {
        display: flex;
        flex-flow: column;
        .v-icon {
          font-size: 18px;
        }
        margin-bottom: 10px;
        strong {
          font-size: 18px;
          margin-bottom: 3px;
          text-transform: capitalize;
        }
        span {
          color: #8c8c8c;
        }
        .v-icon {
          color: #8c8c8c;
          font-size: 20px;
          margin-right: 3px;
        }
        font-size: 14px;
      }
      p {
        font-size: 14px;
      }
      display: flex;
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 15px;
      }
      .detailIcon {
        font-size: 14px;
      }
    }
  }
}
.detailIcon {
  display: flex;
  list-style: none;
  padding-left: 0;
  li {
    display: flex;
    align-items: center;
    color: #8c8c8c;
    .v-icon {
      font-size: 20px;
      margin-right: 10px;
    }
    margin-right: 15px;
  }
}
.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  margin-top: 10px;
  li {
    display: flex;
    &:first-of-type {
      margin-right: 10px;
    }
    &:last-of-type {
      margin-left: 10px;
    }
    .v-icon {
      background: #f4f4f4;
      border-radius: 5px;
    }
  }
}
</style>