<template>
  <section>
    <div class="reviewRating" v-if="loading">
      <LoadingSkeleton  :text="5"/>
      <LoadingSkeleton  :title="1"/>
    </div>
    <div class="reviewRating" v-if="!loading">
      <header>Customer Reviews ({{ total }})</header>
      <ul class="ratingLinear">
        <li v-for="(dat, index) in ratings" :key="index + 'rating'">
          <span>{{ dat.name }}</span>
          <v-progress-linear :value="dat.value" height="6"></v-progress-linear>
          <span> {{ dat.value }}%</span>
        </li>
      </ul>
    </div>
    <v-btn class="viewMore" v-if="!review && !loading" @click="reviewAct" 
      >Write a review</v-btn
    >
    <v-form v-if="review">
      <div class="mb-4" v-if="false">
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
        ></vue-dropzone>
      </div>
      <div>
        <v-text-field
          v-model="title"
          outlined
          placeholder="Review"
        ></v-text-field>
      </div>
      <div>
        <v-textarea
          v-model="description"
          outlined
          placeholder="Review Description"
        ></v-textarea>
      </div>
      <div class="mb-4">
        <v-rating
          v-model="rating"
          size="30"
          color="orange"
          background-color="orange"
        ></v-rating>
      </div>

      <div class="mb-4" >
        <v-btn
          class="viewMore"
          @click="reviewWrite"
          width="30%"
          height="50px"
          v-if="!loading"
          >Review</v-btn
        >
        <v-btn
          class="viewMore"
          width="30%"
          height="50px"
          v-if="loading"
          loading
        ></v-btn>
      </div>
    </v-form>
    <ReviewBox
      v-for="(data, index) in reviews"
      :key="index + 'review'"
      :item="data"
    />
  </section>
</template>

<script>
import ReviewBox from "@/components/ReviewBox";
import LoadingSkeleton from "@/components/LoadingSkeleton";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ReviewCard",
  props: ["id"],
  data: () => ({
    review: false,
    title: undefined,
    description: undefined,
    rating: undefined,
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      thumbnailWidth: 150,
      maxFilesize: 10,
      headers: { "My-Awesome-Header": "header value" },
      addRemoveLinks: true,
    },
    images: undefined,
    loading: false,
    reviews: [],
    ratings: [],
    total: 0,
  }),
  components: {
    ReviewBox,
    vueDropzone: vue2Dropzone,
    LoadingSkeleton,
  },
  methods: {
    ...mapActions(["postRating", "getRatingsFrontend"]),
    reviewAct() {
      this.review = !this.review;
    },
    async reviewWrite() {
      this.loading = true;
      let formdata = new FormData();
      // let fileBundles = this.$refs.myVueDropzone.getAcceptedFiles();
      // fileBundles.forEach((ele) => {
      //   if (ele.type.split("/")[0] == "image") {
      //     this.images.push(ele);
      //   }
      // });
      formdata.append("connectedTo", this.id);
      if (this.title != undefined) {
        formdata.append("title", this.title);
      }
      if (this.description != undefined) {
        formdata.append("description", this.description);
      }
      if (this.rating != undefined) {
        formdata.append("rating", this.rating);
      }
      // if (this.images != undefined) {
      //   formdata.append("images", this.images);
      // }
      await this.postRating(formdata);

      this.review = false;
      this.loading = false;
      this.reviews = this.allRatings.results;
      this.total = this.allRatings.pagination.total;
      this.ratings = this.allRatings.ratings;
    },
  },
  computed: {
    ...mapGetters(["allRatings"]),
  },
  async created() {
    this.loading = true;
    await this.getRatingsFrontend({ connectedTo: this.$route.params.id });
    this.reviews = this.allRatings.results;
    this.ratings = this.allRatings.ratings;
    this.total = this.allRatings.pagination.total;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.reviewedBox {
  border: 2px solid #f4f4f4;
  padding: 15px;
  h3 {
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
  }
  footer {
    display: flex;
    justify-content: space-between;
    ul {
      padding-left: 0;
      list-style: none;
      display: flex;
      font-size: 14px;
      .v-icon {
        font-size: 18px;
        margin-right: 5px;
      }
      li {
        color: #8c8c8c;
        margin-right: 15px;
        display: flex;
        align-items: center;
      }
      &:last-of-type {
        margin-right: 0;
        margin-left: 15px;
      }
    }
  }
}
.reviewRating {
  header {
    margin-bottom: 10px;
    display: block;
  }
}
.ratingLinear {
  padding-left: 0;
  padding: 10px 0 20px;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
    span {
      display: block;
      &:first-of-type {
        width: 70px;
        text-align: left;
      }
      &:last-of-type {
        width: 50px;
        text-align: right;
      }
    }
  }
}
.viewMore {
  background: #0878d4 !important;
  min-height: 50px;
  width: 300px;
  margin-bottom: 25px;
}
</style>